<template>
    <div>
        
    </div>
</template>

<script setup>

</script>

<style scoped>
div {
    margin: 30px 0;
}

</style>