<template>
    <div class="privacy-policy">
        <h1>Privacy Policy</h1>
    </div>
  </template>
  <script>
  
  export default {
    data () {
      return {}
    },
    name: 'AboutView',
    components: {
    },
    mounted () {
      
    }
  }
  </script>
  <style scoped>
  
  </style>