<template>
    <!-- <div class="container"> -->
        <h1>Testimonials</h1>
        <p>Here is a list of good things our previous customers have to say about us. </p>
    
    </template>
    
    <script>
    
    </script>
    
    <style scoped>
    .container {
    
    }
    </style>